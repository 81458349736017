import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Register from 'components/Register';
import fetch from 'utils/fetch';
import { THackathon } from 'types';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';

import Banner from './Banner';
import Summary from './Summary';

import { HACKATHON_API } from 'constants/app';

export default function () {
  const { hackathonId: hackathonIdRaw } = useParams() as any;
  const hackathonId = hackathonIdRaw && parseInt(hackathonIdRaw);

  const [selectedHackathon, setselectedHackathon] = useState<THackathon>();
  const [hackathons, sethackathons] = useState<THackathon[]>();
  const [loading, setloading] = useState<boolean>(true);
  const [error, seterror] = useState<string>('');

  const getHackathons = async () => {
    try {
      const resp = await fetch(HACKATHON_API, { credentials: 'header' });
      const respJSON = await resp.json();
      if (resp.status !== 200) throw Error(respJSON.error);

      const hackathonData = respJSON.data;
      sethackathons(hackathonData);
      setHackathon(hackathonData, hackathonId);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  const setHackathon = (hackathons: THackathon[], id: number) => {
    if (!hackathons) return;
    const selectedHackathon = hackathons.find((h: THackathon) => h.id === id);
    setselectedHackathon(selectedHackathon);
  };

  useEffect(() => {
    if (!hackathons || !hackathons.length) return;
    setHackathon(hackathons, hackathonId);
  }, [hackathonId]);

  useEffect(() => {
    getHackathons();
  }, []);

  return (
    <div>
      {(() => {
        if (loading) return <Spinner bar />;
        if (error) return <ErrorMsg text={error} embed={false} preset="broken" />;
        return (
          <div>
            <Banner selected={selectedHackathon} hackathons={hackathons} />
            <Register />
            {selectedHackathon && <Summary id={selectedHackathon.id} />}
          </div>
        );
      })()}
    </div>
  );
}
