import React from 'react';
import useStore from 'globalstate';

export default function NoAwardsBubble() {
  const [State] = useStore();
  const { isContestActive } = State;

  if (isContestActive) return null;
  return (
    <div className="bg-red-500 px-4 py-2 w-max rounded-full text-white text-sm text-center mt-2">
      This hackathon is now closed. New submissions will not get awards.
    </div>
  );
}
