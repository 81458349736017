import React from 'react';

type InfoLineProps = {
  info: string | null;
  Icon?: any;
  success?: boolean;
  color?: string;
  className?: string;
};

export function InfoLine(props: InfoLineProps) {
  const { className, info, success = false, Icon } = props;
  const color = props.color ? props.color : success ? 'green-500' : 'red-500';

  return (
    <div
      className={`text-${color} uppercase font-medium text-xs mt-2
        flex items-center${className ? ` ${className}` : ''}`}
    >
      {info && Icon && <Icon className={`text-${color} mr-1`} />}
      {info}
    </div>
  );
}
