import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaUser, FaSignInAlt, FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { HACKATHON_LIST_PATH, getLoginURL, getLogoutURL } from 'constants/app';
import useStore from 'globalstate';

const RenderLink = ({ label, link, currentPath }: { label: string; link: string; currentPath: string }) =>
  link.startsWith('http') ? (
    <a href={link} className="text-lg" aria-current="page">
      {label}
    </a>
  ) : (
    <Link to={link} className={`text-lg ${currentPath === link ? 'text-brand font-bold' : ''}`} aria-current="page">
      {label}
    </Link>
  );

const RenderMobileLink = ({ label, link, currentPath }: { label: string; link: string; currentPath: string }) =>
  link.startsWith('http') ? (
    <a href={link} className="text-left bg-white block px-4 py-2 text-base font-space" aria-current="page">
      {label}
    </a>
  ) : (
    <Link
      to={link}
      className={`text-left bg-white block px-4 py-2 text-base font-space ${
        currentPath === link ? 'text-brand font-bold' : ''
      }`}
      aria-current="page"
    >
      {label}
    </Link>
  );

const links = [
  { label: 'Hackathons', link: `${HACKATHON_LIST_PATH}/` },
  { label: 'Discussions', link: 'https://discourse.univ.ai/c/ghfhack/27' },
];

export default function NavBar() {
  const [State] = useStore();
  const { isAuthenticated, userDetails } = State;
  const location = useLocation();

  const loginURL = getLoginURL(window.location.href);
  const logoutURL = getLogoutURL(window.location.href);
  const currentPath = location.pathname;

  const [showUserMenu, setshowUserMenu] = useState<boolean>(false);

  return (
    <nav>
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img className="block lg:hidden h-6 w-auto" src="/logo.svg" alt="Univ.AI" />
              <img className="hidden lg:block h-6 w-auto" src="/logo.svg" alt="Univ.AI" />
            </Link>
          </div>
          <div className="hidden sm:block sm:ml-6">
            <div className="flex space-x-8">
              {links.map(link => (
                <RenderLink key={link.label} currentPath={currentPath} {...link} />
              ))}
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="ml-3 relative">
              <div>
                <button
                  type="button"
                  className="block sm:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 bg-white hover:bg-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setshowUserMenu(!showUserMenu)}
                >
                  <span className="sr-only">Open main menu</span>
                  <FaBars className="block h-6 w-6" aria-hidden="true" />
                  <FaTimes className="hidden h-6 w-6" aria-hidden="true" />
                </button>
                <div className="hidden sm:block">
                  {isAuthenticated ? (
                    <button
                      type="button"
                      onClick={() => {
                        setshowUserMenu(!showUserMenu);
                      }}
                      className="button outline"
                      id="user-menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <div className="flex flex-row items-center">
                        <span className="mr-2 lowercase text-base font-normal tracking-tight sm:tracking-normal">
                          <FaUser className="inline mr-2" />
                          <span className="hidden sm:inline-block">{userDetails?.username}</span>
                        </span>
                        {showUserMenu ? <FaCaretUp /> : <FaCaretDown />}
                      </div>
                    </button>
                  ) : (
                    <div className="p-4 flex justify-center">
                      <a href={loginURL} className="button flex items-center">
                        <FaSignInAlt className="mr-2" /> Log in
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className={`hidden origin-top-left absolute right-0 sm:mt-2 w-52 shadow-lg pb-2 sm:py-2 bg-white
                    ring-1 ring-black ring-opacity-5 focus:outline-none
                    ${showUserMenu ? 'sm:block' : 'sm:hidden'}`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  <div
                    role="menuitem"
                    className="text-center bg-white block px-4 py-2 text-base font-space bg-gray-100 sm:hidden"
                  >
                    {userDetails?.name}
                  </div>
                  <a
                    role="menuitem"
                    href={logoutURL}
                    className="text-left bg-white block px-4 py-2 text-base font-space"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${showUserMenu ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <div
            role="menuitem"
            className="text-center bg-white block px-4 py-2 text-base font-space bg-gray-100 sm:hidden"
          >
            {isAuthenticated ? (
              userDetails?.name
            ) : (
              <a href={loginURL} className="button flex items-center">
                <FaSignInAlt className="mr-2" /> Log in
              </a>
            )}
          </div>
          {links.map(link => (
            <RenderMobileLink key={`mobile-${link.label}`} currentPath={currentPath} {...link} />
          ))}
          <a role="menuitem" href={logoutURL} className="text-left bg-white block px-4 py-2 text-base font-space">
            Logout
          </a>
        </div>
      </div>
    </nav>
  );
}
