import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload, FaCheck, FaTimes, FaRedoAlt, FaFile } from 'react-icons/fa';
import * as Sentry from '@sentry/react';

import Spinner from 'fragments/Spinner';
import fetchXHR from 'utils/fetch-xhr';
import { toast } from 'react-toastify';

import { TUploadParams, TUpFields } from './';

type TUploadOpts = {
  accept?: string[];
  minSize?: number;
  maxSize?: number;
};

interface IProps {
  triggerUpload: boolean;
  onComplete?: () => void;
  onError?: (err: string) => void;
  onSelect: (file: File) => void;
  options: TUploadOpts;
  uploadParams?: TUploadParams;
  triggerReset?: number;
}

export default function (props: IProps) {
  const { triggerReset, triggerUpload, options, uploadParams } = props;
  const { accept, minSize, maxSize } = options;

  const [error, seterror] = useState<string>();
  const [selectedFile, setselectedFile] = useState<File | null>(null);
  const [uploadProgress, setuploadProgress] = useState<number>(0);
  const [isUploadDone, setisUploadDone] = useState<boolean>(false);
  const [isUploading, setisUploading] = useState<boolean>(false);

  const captureUploadProgress = (e: ProgressEvent) => {
    const { loaded, total } = e;
    setuploadProgress((loaded / total) * 100);
  };

  const doUpload = async (file: File, uploadParams: TUploadParams) => {
    if (!uploadParams) {
      seterror('Was unable to get upload URL. Please try again later.');
      return;
    }

    setisUploading(true);
    const form = new FormData();
    (Object.keys(uploadParams.fields) as TUpFields[]).forEach(key => form.append(key, uploadParams.fields[key]));
    form.append('file', file);

    fetchXHR(
      uploadParams.url,
      {
        method: 'POST',
        body: form,
      },
      captureUploadProgress
    )
      .then(resp => {
        if (resp.status !== 200 && resp.status !== 204) throw new Error(resp.text);
        setisUploadDone(true);
        setisUploading(false);
        if (props.onComplete) props.onComplete();
      })
      .catch((e: ProgressEvent) => {
        Sentry.captureMessage(`${file.name} ${file.size} ${file.type} - ${e.toString()}`);
        const genericErrorMessage = 'Upload failed. Check file size limits, file format and try again';
        seterror(genericErrorMessage);
        setselectedFile(null);
        if (props.onError) props.onError(genericErrorMessage);
        setisUploadDone(true);
        setisUploading(false);
      });
  };

  const resetFail = () => {
    seterror('');
    setisUploadDone(false);
  };

  const onFileSelect = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      const errMsg = rejectedFiles[0].errors.map((e: { code: string; message: string }) => e.message).join('. ');
      seterror(errMsg);
      return;
    }

    resetFail();
    try {
      const file: File = acceptedFiles[0];
      setselectedFile(file);
      props.onSelect(file);
    } catch (e) {
      Sentry.captureException(e);
      const genericMessage = 'File seems to be invalid. Please make sure your file meets our upload criterion.';
      seterror(genericMessage);
      if (props.onError) props.onError(genericMessage);
    }
  }, []);

  useEffect(() => {
    if (!triggerUpload) return;
    if (!selectedFile || !uploadParams) {
      toast.error('Something went wrong with the submission. Please try again');
      return;
    }
    doUpload(selectedFile, uploadParams);
  }, [selectedFile, uploadParams, triggerUpload]);

  useEffect(() => {
    if (!triggerReset) return;
    setselectedFile(null);
    setisUploadDone(false);
  }, [triggerReset]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    minSize,
    maxSize,
    onDrop: onFileSelect,
  });

  return (
    <div
      {...(isUploading || isUploadDone ? {} : getRootProps())}
      className={`border-2 border-gray-300 pointer-cursor p-12 my-2 text-gray-500
        flex flex-col items-center justify-center 
      `}
    >
      <input {...getInputProps()} />
      {(() => {
        if (isUploading) return <Spinner size="tiny" />;
        if (error) return <FaTimes className="mb-2 text-red-500 text-4xl" />;
        if (isUploadDone) return <FaCheck className="mb-2 text-green-500 text-4xl" />;
        if (selectedFile) return <FaFile className="mb-2 text-4xl" />;
        return <FaUpload className="mb-2 text-4xl" />;
      })()}
      {(() => {
        if (isUploading)
          return (
            <div className="h-4 w-full mt-2">
              <div className="h-full bg-brand rounded-lg" style={{ width: `${uploadProgress}%` }} />
            </div>
          );
        if (error)
          return (
            <p className="flex flex-col items-center justify-center">
              {error}
              <button type="button" onClick={resetFail} className="mt-2 flex items-center">
                <FaRedoAlt className="mr-2" /> Try again
              </button>
            </p>
          );
        if (selectedFile) return <p>{selectedFile.name}</p>;
        if (isUploadDone) return <p>Upload Successful</p>;
        if (isDragActive) return <p>Drop the files here ...</p>;
        return <p>Drag and drop or click to select file</p>;
      })()}
    </div>
  );
}
