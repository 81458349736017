import React, { useState } from 'react';

import Tabs from 'fragments/Tabs';

import Leaderboard from './Board';

const tabs = [{ label: 'public' }, { label: 'private' }];

export default function TabbedBoard() {
  const [selectedTab, setselectedTab] = useState<string>('public');

  return (
    <div>
      <h1>Leaderboards</h1>
      <hr />
      <Tabs tabs={tabs} currentTab={selectedTab} setTab={setselectedTab} />
      <div className={selectedTab === 'public' ? 'block' : 'hidden'}>
        <Leaderboard type="public" />
      </div>
      <div className={selectedTab === 'private' ? 'block' : 'hidden'}>
        <Leaderboard type="private" />
      </div>
    </div>
  );
}
