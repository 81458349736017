import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { FaAngleDoubleRight, FaDownload } from 'react-icons/fa';

import Submission from 'components/Submission';
import Leaderboard from 'components/Leaderboard';
import Discussion from 'components/Discussion';

import NoAwardsBubble from './NoAwardsBubble';
import MarkdownHeader from '../MarkdownRender/Headers';

export default function RenderMarkdownPage({ markdown }: { markdown: string }) {
  const MarkdownOptions = {
    overrides: {
      submission: {
        component: Submission,
      },
      discussion: {
        component: Discussion,
      },
      leaderboard: {
        component: Leaderboard,
      },
      noawards: {
        component: NoAwardsBubble,
      },
      a: {
        component: (props: { [key: string]: string }) => (
          <a href={props.href} target="_blank" rel="noreferrer noopener">
            {props.children}
          </a>
        ),
      },
      img: {
        component: (props: { [key: string]: string }) => <img className="w-full h-auto" src={props.src} />,
      },
      nav: {
        component: (props: { [key: string]: string }) => (
          <div className="flex items-center justify-between">
            <div className="border-t border-gray-100 flex-1 mr-4" />
            <Link className="button outline mt-2" to={props.to}>
              {props.label} <FaAngleDoubleRight className="inline ml-2" />
            </Link>
          </div>
        ),
      },
      data: {
        component: (props: { links: string }) => {
          const links: { url: string; label: string }[] = JSON.parse(props.links);
          return (
            <div className={`flex flex-col sm:flex-row ${links.length > 0 ? 'my-4 sm:my-0' : ''}`}>
              {links.map(l => (
                <a
                  className="button bg-blue-100 py-4 px-6 my-2 mr-0 sm:mr-2 hover:bg-blue-300 text-black"
                  href={l.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {l.label} <FaDownload className="inline ml-2" />
                </a>
              ))}
            </div>
          );
        },
      },
      h1: {
        component: MarkdownHeader,
        props: { tag: 'h1' },
      },
      h2: {
        component: MarkdownHeader,
        props: { tag: 'h2' },
      },
      h3: {
        component: MarkdownHeader,
        props: { tag: 'h3' },
      },
      h4: {
        component: MarkdownHeader,
        props: { tag: 'h4' },
      },
      h5: {
        component: MarkdownHeader,
        props: { tag: 'h5' },
      },
      table: {
        component: (props: { [key: string]: string }) => (
          <div className="overflow-x-auto max-h-70vh">{props.children}</div>
        ),
      },
    },
  };

  return <Markdown options={MarkdownOptions}>{markdown}</Markdown>;
}
