import React from 'react';

type TTab = { label: string; icon?: React.ReactElement };

interface IProps {
  tabs: TTab[];
  currentTab: string;
  setTab: (key: string) => void;
}

export default function Tabs(props: IProps) {
  const { tabs, currentTab, setTab } = props;

  return (
    <div className="flex flex-row flex-wrap justify-center items-center max-w-screen space-x-4">
      {tabs.map((tab: TTab) => {
        const isActive = currentTab === tab.label;
        return (
          <button
            key={`tab-${tab.label}`}
            disabled={isActive}
            onClick={() => setTab(tab.label)}
            className={`button bg-transparent hover:bg-gray-50 text-brand border-0 border-b-4 font-bold text-base ${
              isActive ? 'border-brand' : 'border-gray-300'
            }`}
          >
            {tab.label}
            {tab.icon}
          </button>
        );
      })}
    </div>
  );
}
