import React from 'react';
import globalHook, { Store } from 'use-global-hook';

// state
type State = { [key: string]: any };
const initialState: {} = {};

// actions
type Actions = {
  setState: ({}) => void;
};
const setState = (store: Store<State, Actions>, data: {}) => {
  const state = { ...store.state, ...data };
  store.setState(state);
};
const actions = { setState };

// store
export default globalHook<State, Actions>(React, initialState, actions);
