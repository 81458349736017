import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FaBars, FaCheck, FaCopy } from 'react-icons/fa';

import Error from 'components/Error';

import useStore from 'globalstate';

type TMenu = {
  title: string;
  path: string;
  menus?: TMenu[];
  icon?: JSX.Element;
};

type TSidemenuStatus = { [path: string]: boolean };

export default function NavBar({ hackathonId }: { hackathonId: string }) {
  const [isOpen, setisOpen] = useState<boolean>(window.innerWidth > 768);
  const [copied, setCopied] = useState<boolean>(false);
  const [sidemenuStatus, setsidemenuStatus] = useState<TSidemenuStatus>({});

  const [State] = useStore();
  const location = useLocation();

  const { toc, hackathonData, isContestActive, isPracticeActive } = State;

  const handleSubmenuChange = (path: string, status: boolean) => {
    const newsidemenuStatus = { ...sidemenuStatus, [path]: status };
    setsidemenuStatus(newsidemenuStatus);
  };

  const email = 'ghf@univ.ai';
  const hasCopy = !!navigator?.clipboard?.writeText;
  const copyMail = () => {
    if (!hasCopy) return;
    navigator.clipboard.writeText(email);
    setCopied(true);
  };

  const renderMenus = (menu: TMenu | null) => {
    if (!menu) return <hr />;
    const { path, title, menus, icon } = menu;
    const isActive = location.pathname === path;
    if (menus) {
      // highlights when a child is active but the submenu is collapsed
      const issidemenuActive = menus.some(m => m.path === location.pathname) && !sidemenuStatus[path];
      return (
        <SubMenu
          onOpenChange={(open: boolean) => {
            handleSubmenuChange(path, open);
          }}
          className={issidemenuActive ? 'bg-brand-lightest text-brand link-white-hover' : ''}
          title={title}
        >
          {menus.map(renderMenus)}
        </SubMenu>
      );
    }

    return (
      <MenuItem
        key={`navbar-${title}`}
        className={isActive ? 'bg-brand-lightest text-brand link-white-hover' : ''}
        icon={icon}
      >
        <Link to={path} className={isActive ? 'link-white-hover' : ''}>
          {title}
        </Link>
      </MenuItem>
    );
  };

  if (!location.pathname.startsWith('/hackathon/')) return null;
  if (!hackathonData || !toc)
    return <Error text="Unable to get data. Please contact us if problem persists." preset="broken" />;

  return (
    <React.Fragment>
      <div className="absolute sm:relative top-0 sidebar-container sm:mt-2">
        <div className="fixed z-1010" style={{ height: 'calc(100vh - 70px)' }}>
          <ProSidebar toggled={isOpen} onToggle={setisOpen} breakPoint="md">
            <SidebarHeader>
              <div className="my-4">
                <h5 className="w-full flex justify-center text-sm uppercase tracking-widest text-gray-500">
                  Hackathon {hackathonData.externalId}
                </h5>
                <h4 className="w-full flex justify-center font-medium">{hackathonData.title}</h4>
                <hr />
              </div>
            </SidebarHeader>
            {State.remainingDays === 1 && (
              <SidebarHeader>
                <div className="bg-red-400 p-1 font-space uppercase text-xs text-center text-white">
                  Submissions close at <span className="text-sm">11:59PM</span>
                </div>
              </SidebarHeader>
            )}
            <SidebarHeader>
              {isContestActive ? (
                <div className="bg-green-500 px-4 py-2 rounded-full text-white text-sm text-center mx-4 uppercase">
                  Hackathon Active
                </div>
              ) : isPracticeActive ? (
                <div className="bg-blue-500 px-4 py-2 rounded-full text-white text-sm text-center mx-4 uppercase">
                  Open for Practice
                </div>
              ) : (
                <div className="bg-red-500 px-4 py-2 rounded-full text-white text-sm text-center mx-4 uppercase">
                  Closed
                </div>
              )}
            </SidebarHeader>
            <SidebarContent>
              <Menu iconShape="square">{toc.map(renderMenus)}</Menu>
            </SidebarContent>
            <hr className="mt-0" />
            <SidebarFooter>
              <div className="px-4 pb-4 text-center text-sm mb-4">
                <div>Need help? Contact us at</div>
                <div>
                  {email}
                  {hasCopy ? (
                    <span
                      className={`inline-block ml-1 ${!copied ? 'cursor-pointer' : 'cursor-default'}`}
                      onClick={copyMail}
                      title={copied ? 'Copied!' : 'Copy Email'}
                    >
                      {copied ? <FaCheck /> : <FaCopy />}
                    </span>
                  ) : null}
                </div>
              </div>
            </SidebarFooter>
          </ProSidebar>
        </div>
      </div>
      <div
        onClick={() => {
          setisOpen(!isOpen);
        }}
        className={`block h-12 w-12 p-4 rounded-full flex items-center justify-between fixed bottom-2 right-2
          bg-black md:hidden
      `}
      >
        <FaBars color="white" />
      </div>
    </React.Fragment>
  );
}
