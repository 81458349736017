import React from 'react';
import { useHistory } from 'react-router-dom';

import { THackathon } from 'types';

interface IProps {
  selected?: THackathon;
  hackathons?: THackathon[];
}

export default function Banner(props: IProps) {
  const history = useHistory();
  const { selected, hackathons } = props;
  const selectedId = selected?.id;

  const onChooseHackathon = (e: any) => {
    const id = e.target.value;
    history.replace(id);
  };

  const options = hackathons?.map(h => ({
    id: h.id,
    label: h.title,
    externalId: h.externalId,
  }));

  if (!selected) return null;

  const {
    externalId,
    title,
    description,
    difficulty,
    start,
    end,
    isActive,
    practiceMode,
    participantCount,
    submissionCount,
  } = selected;
  const startDate = new Date(start).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  const endDate = new Date(end).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });

  if (!options) return null;
  return (
    <div className="bg-darkbg text-white w-full px-8 py-12 flex items-center relative">
      <div>
        <select onChange={onChooseHackathon} className="bg-darkbg w-max absolute top-4 right-4">
          {options.map(opt => (
            <option selected={selectedId === opt.id} key={opt.id} value={opt.id}>
              {opt.externalId} {opt.label}
            </option>
          ))}
        </select>
        <div className="max-w-prose pt-8 sm:pt-0">
          <h3 className="text-white">Hackacthon {externalId}</h3>
          <h1 className="text-white">{title}</h1>
          <div className="mt-4 text-white">{description}</div>
          <div className="mt-8">
            <div className="space-x-8">
              {participantCount && <span>Participants: {participantCount}</span>}
              {submissionCount && <span>Submissions: {submissionCount}</span>}
            </div>
            <div className="space-x-8">
              {startDate && <span>Start Date: {startDate}</span>}
              {endDate && <span>End Date: {endDate}</span>}
            </div>
            <div className="space-x-8">
              {difficulty && <span>Difficulty: {difficulty}</span>}
              <span>Status: {isActive ? 'Active' : practiceMode ? 'Open for Practice' : 'Closed'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
