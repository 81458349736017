export const isNotDev = process.env.REACT_APP_ENV !== 'development';
const DOMAIN_URL = isNotDev
  ? (() => {
      // assuming we'll be running content and course servers as subdomains
      const { host } = window.location;
      const hostComponents = host.split('.');
      hostComponents.shift();
      return hostComponents.join('.');
    })()
  : 'dev.univ.ai';
const PROTOCOL = isNotDev ? 'https' : 'http';

export const APPLICATION_SITE_URL = `${PROTOCOL}://application.${DOMAIN_URL}`;
export const AUTH_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;
export const USER_SITE_URL = `${PROTOCOL}://my.${DOMAIN_URL}`;
export const API_URL = `${PROTOCOL}://api.hack.${DOMAIN_URL}`;
export const DISCOURSE_URL = process.env.REACT_APP_DISCOURSE_URL;
export const DISCOURSE_INTRODUCTIONS_URL = process.env.REACT_APP_DISCOURSE_INTRODUCTION_URL;
export const DISCOURSE_INTRODUCTIONS_ID = process.env.REACT_APP_DISCOURSE_INTRODUCTION_TOPIC_ID;
export const DISCOURSE_CATEGORY_URL = process.env.REACT_APP_DISCOURSE_CATEGORY_URL;

export const getLoginURL = (redirectURL?: string) => `${APPLICATION_SITE_URL}/login?from=${redirectURL}`;
export const getRegistrationURL = (redirectURL?: string) => `${APPLICATION_SITE_URL}/register?from=${redirectURL}`;
export const getLogoutURL = (redirectURL?: string) =>
  `${AUTH_URL}/logout${redirectURL ? `?redirect=${redirectURL}` : ''}`;
export const getAuthRefreshURL = (fromURL: string) => `${AUTH_URL}/jwt/refresh?from=${fromURL}`;
export const getProfileURL = (redirectURL: string, context: string) =>
  `${USER_SITE_URL}/profile?context=${context}&from=${redirectURL}`;

export const REGISTER_PATH = '/register';
export const HACKATHON_BASE_PATH = '/hackathon';
export const HACKATHON_LIST_PATH = '/hackathons';
export const CURRENT_HACKATHON_PATH = '/hackathons/current';
export const ACTIVE_HACKATHON_PATH = '/hackathons/active';
export const PAST_HACKATHON_PATH = '/hackathons/past';

export const HACKATHON_API = `${API_URL}/contest`;
