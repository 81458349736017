import React from 'react';
import Modal from 'react-modal';
// import { Link } from 'react-router-dom';
import { FaClipboardList, FaTimesCircle } from 'react-icons/fa';

interface IProps {
  score: string;
  isOpen: boolean;
  close: () => void;
}

export default function (props: IProps) {
  const { close, isOpen, score } = props;

  return (
    <Modal
      isOpen={isOpen}
      portalClassName="absolute top-0 left-0"
      overlayClassName="z-1010 relative bg-black bg-opacity-70 h-screen w-screen flex items-center justify-center"
      className="p-4 m-4 h-4/5 w-full bg-gray-50 overflow-auto xl:w-5/12 lg:w-1/2 md:w-3/4 md:px-12 md:py-8 sm:w-3/5 md:h-50vh"
      onRequestClose={close}
    >
      <div className="h-full w-full flex flex-col justify-between">
        <div className="cursor-pointer text-2xl text-gray-500 hover:text-gray-600 flex justify-between" onClick={close}>
          <div />
          <FaTimesCircle />
        </div>
        <div className="h-full w-full flex flex-col items-center justify-center">
          <span className="text-md text-gray-500 mb-4">SCORE</span>
          <div>
            {typeof score === 'number' ? (
              <div className="text-2xl md:text-5xl">{score}</div>
            ) : (
              (Object.keys(score) as Array<keyof typeof score>).map(key => (
                <div className="uppercase text-2xl md:text-3xl">
                  <span className="text-gray-500 w-32 md:w-48 inline-block">{key}</span> {score[key]}
                </div>
              ))
            )}
          </div>
          <div className="bg-green-50 text-green-500 p-4 flex items-center justify-center text-xs sm:text-base mt-4">
            <FaClipboardList className="mr-2 text-xl" />
            <span>Your submission has been evaluated</span>
          </div>
        </div>
        <hr />
        {/* <div className="w-full px-4 flex flex-col justify-center self-end md:flex-row">
          <Link className="button mt-2 md:mt-0" to={LEADERBOARD_PATH}>
            Leaderboard
          </Link>
        </div> */}
      </div>
    </Modal>
  );
}
