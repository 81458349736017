import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

import useStore from 'globalstate';
import Error from 'components/Error';
import fetch from 'utils/fetch';
import { getCookieValue } from 'utils/cookies';
import { getAuthRefreshURL, getLoginURL } from 'constants/app';
import { API_URL } from 'constants/app';

type AuthCookie = {
  aud: string;
  dashboard: string;
  tokens: string;
  exp: number;
  full: string;
  iat: number;
  iss: string;
  roles: string;
  sub: string;
  userId: number;
  username: string;
  name: string;
};

interface IProps {
  children: JSX.Element;
}

export default function Preloader(props: IProps) {
  const [, Actions] = useStore();

  const [resettingToken, setResettingToken] = useState<boolean>(false);
  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [error, seterror] = useState<string>('');

  const resetCookie = async () => {
    setResettingToken(true);
    localStorage.setItem('lastReset', Date.now().toString());
    window.location.replace(getAuthRefreshURL(window.location.href));
  };

  const maybeResetCookie = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return;

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const loginURL = getLoginURL(window.location.href);
    if (!authInfo.userId) return window.location.replace(loginURL);

    const lastResetRaw = localStorage.getItem('lastReset');
    if (!lastResetRaw) return resetCookie();

    const lastReset = parseInt(lastResetRaw);
    const oneMinute = 1000 * 60;
    if (lastReset < Date.now() + oneMinute) return;

    resetCookie();
  };

  const checkAuthentication = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return Actions.setState({ userDetails: null, isAuthenticated: false, token: null });

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const userDetails = { ...authInfo };
    const cookieHasExpired = authInfo.exp > Date.now();
    if (cookieHasExpired) return resetCookie();

    const isAuthenticated = !!authInfo.username;
    Actions.setState({ isAuthenticated, userDetails, token: maybeAuthCookie });
  };

  const checkAccess = async () => {
    try {
      const INDEX_API = `${API_URL}/contestant/valid`;
      const resp = await fetch(INDEX_API, { credentials: 'header' });
      const { hasAccess, refreshAuth } = await resp.json();
      if (refreshAuth) return resetCookie();
      Actions.setState({ hasHackathonAccess: hasAccess });
      sethasPreloaded(true);
    } catch (e) {
      seterror((e as Error).message);
      sethasPreloaded(true);
    }
  };

  useEffect(() => {
    maybeResetCookie();
    checkAuthentication();
    checkAccess();
  }, []);

  if (!hasPreloaded || resettingToken)
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="h-screen w-screen flex items-center justify-center">
          <img src="/logo.svg" className="h-8 w-auto" />
        </div>
      </div>
    );

  if (error) return <Error text={error} preset="broken" embed={false} />;

  return <div>{props.children}</div>;
}
