import React from 'react';
import { ReactElement } from 'react-markdown';
import { Link } from 'react-router-dom';

interface IProps {
  animate?: boolean;
  link?: string;
  onClick?: () => void;
  topContent: ReactElement;
  bottomContent: ReactElement;
  style?: string;
}

export default function Card(props: IProps) {
  const { animate = false, link, onClick, topContent, bottomContent, style } = props;

  const Content = (
    <div
      onClick={!link ? onClick : undefined}
      className={`"flex-1 border-4 border-solid border-brand-dark rounded-xl w-full transform ${
        animate ? 'hover:bg-brand-dark hover:text-white hover:scale-105' : ''
      } transition duration-500 ${style}`}
    >
      <div className="flex flex-col h-full justify-between">
        {topContent}
        <div className="flex items-center justify-between items-center p-4 bg-brand-dark">{bottomContent}</div>
      </div>
    </div>
  );

  if (!link) return Content;
  return (
    <Link to={link} className="w-full">
      {Content}
    </Link>
  );
}
