import React, { useState, useEffect } from 'react';
import useStore from 'globalstate';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaChevronRight, FaDiscourse, FaLockOpen, FaUnlock } from 'react-icons/fa';

import Spinner from 'fragments/Spinner';
import {
  API_URL,
  DISCOURSE_URL,
  DISCOURSE_CATEGORY_URL,
  DISCOURSE_INTRODUCTIONS_URL,
  DISCOURSE_INTRODUCTIONS_ID,
  REGISTER_PATH,
} from 'constants/app';
import fetch from 'utils/fetch';
import ErrorMsg from 'components/Error';
// import DiscourseIFrame from './DiscourseIFrame';
import { toast } from 'react-toastify';

const getDiscourseUserURL = (userId: string) => `${DISCOURSE_URL}/u/${userId}.json`;
const DISCOURSE_REGISTER_URL = `${API_URL}/contestant/discourse`;
const GHF_HACKATHON_ROLE = 'ghf_h';

export default function Discussions() {
  const [error, seterror] = useState<string>();
  const [loading, setloading] = useState<boolean>(true);
  const [registered, setregistered] = useState<boolean>(true);
  const [isLoggedIn, setisLoggedIn] = useState<boolean>(false);
  const [hasAccess, sethasAccess] = useState<boolean>(false);
  const [State] = useStore();

  const { isAuthenticated, userDetails } = State;

  const getUserStatus = async () => {
    try {
      const resp = await fetch(getDiscourseUserURL(userDetails.username), { credentials: 'include' });
      if (resp.status === 404) {
        setloading(false);
        return setisLoggedIn(false);
      }
      if (resp.status !== 200) throw new Error(await resp.text());
      const respJSON = await resp.json();
      setloading(false);
      sethasAccess(respJSON?.user?.groups.find((g: any) => g.name === GHF_HACKATHON_ROLE));
      setisLoggedIn(!!respJSON?.user?.user_option); // key in response chosen randomly that is only present if you are authenticated
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  const getDiscourseAccess = async () => {
    try {
      const resp = await fetch(DISCOURSE_REGISTER_URL, { credentials: 'header', method: 'POST' });
      const respJSON = await resp.json();
      if (respJSON.success) {
        getUserStatus();
        return toast.success('Registration complete. You can now post on GHF Discourse.');
      }

      seterror(respJSON.error);
      if (respJSON.error.startsWith('You are not registered')) setregistered(false);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    getUserStatus();
  }, []);

  const hasIntroductions = !!(DISCOURSE_INTRODUCTIONS_URL && DISCOURSE_INTRODUCTIONS_ID);

  return (
    <div>
      {(() => {
        if (!isAuthenticated) return <ErrorMsg preset="login" />;
        if (!registered)
          return (
            <ErrorMsg
              preset="login"
              emotext="Complete hackathon registration"
              extrablock={() => (
                <div>
                  <Link className="button" to={REGISTER_PATH}>
                    Register
                  </Link>
                  <div className="text-center mt-4">
                    <a className="text-gray-500" href={DISCOURSE_CATEGORY_URL} target="_blank">
                      Let me see discussions <FaChevronRight className="inline ml-2 text-xs" />
                    </a>
                  </div>
                </div>
              )}
            />
          );
        // if (!contestId) return <ErrorMsg text="Invalid hackathon" preset="broken" />;
        if (loading) return <Spinner bar space />;
        if (!isAuthenticated) return <ErrorMsg preset="login" />;
        if (error) return <ErrorMsg text={error} preset="broken" />;
        return (
          <div>
            <h2>Discussions</h2>
            <hr />
            {(!isLoggedIn || !hasAccess) && (
              <p className="text-gray-400">
                Access to hackathon discussions is restricted. Please read and follow these steps to get access
              </p>
            )}
            <ul>
              {!isLoggedIn ? (
                <li>
                  You are not logged in to your discourse account
                  <a className="button small outline inline ml-2" target="_blank" href={`${DISCOURSE_URL}/login`}>
                    Login <FaUnlock className="inline" />
                  </a>
                </li>
              ) : (
                <li>
                  Log in to{' '}
                  <a href={DISCOURSE_URL} target="_blank">
                    our Discourse
                  </a>{' '}
                  to get started.
                  <FaCheckCircle className="inline ml-2 text-green-600" />
                </li>
              )}
              {!isLoggedIn ? (
                <li>Refresh this page after you are logged in to Discourse</li>
              ) : hasAccess ? (
                <li>
                  You have access to post to GHF&apos;s Discourse
                  <FaCheckCircle className="inline ml-2 text-green-600" />
                </li>
              ) : (
                <li>
                  Get added to our GHF group to start posting
                  <button className="button small outline inline ml-2" onClick={getDiscourseAccess}>
                    Get access <FaLockOpen className="inline" />
                  </button>
                </li>
              )}
              {isLoggedIn && hasAccess && hasIntroductions && (
                <li>
                  Introduce yourself on our{' '}
                  <a className="button small outline inline ml-2" target="_blank" href={DISCOURSE_INTRODUCTIONS_URL}>
                    Introductions thread <FaDiscourse className="inline" />
                  </a>
                </li>
              )}
              {isLoggedIn && hasAccess && (
                <li>
                  Visit our hackathon discourse thread
                  <a className="button small outline inline ml-2" target="_blank" href={DISCOURSE_CATEGORY_URL}>
                    Hackathon Discussion <FaDiscourse className="inline" />
                  </a>
                </li>
              )}
              <hr />
              {/* {isLoggedIn && hasIntroductions && (
                <div className="h-50vh mt-8">
                  <DiscourseIFrame
                    discourseURL={DISCOURSE_URL as string}
                    topicId={DISCOURSE_INTRODUCTIONS_ID as string}
                  />
                </div>
              )} */}
            </ul>
          </div>
        );
      })()}
    </div>
  );
}
