import React, { useEffect, useState } from 'react';

import Error from 'components/Error';
import Markdown from 'components/MarkdownRender';
import Spinner from 'fragments/Spinner';
import { API_URL } from 'constants/app';
import fetch from 'utils/fetch';

export default function RenderMarkdownPage({ id }: { id: number }) {
  const [error, seterror] = useState<string>();
  const [loading, setloading] = useState<boolean>(true);
  const [markdown, setmarkdown] = useState<string>();

  const getPage = async (hackathonId: number) => {
    try {
      const INDEX_API = `${API_URL}/contest/summary/${hackathonId}`;
      const resp = await fetch(INDEX_API, { credentials: 'header' });
      const data = await resp.text();
      setmarkdown(data);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  useEffect(() => {
    setmarkdown('');
    setloading(true);
    getPage(id);
  }, [id]);

  if (loading)
    return (
      <div className="h-90vh">
        <Spinner bar />
      </div>
    );
  if (error) return <Error preset="broken" text={error} />;
  if (!markdown)
    return (
      <Error
        preset="broken"
        text="Something went wrong while getting this page. Please contact us if problem persists"
      />
    );

  return (
    <div className="pb-20 px-4 sm:px-0">
      <Markdown markdown={markdown} />
    </div>
  );
}
