import React from 'react';

interface IProps {
  children: JSX.Element;
  tag: string;
}

export default function MarkdownHeaders(props: IProps) {
  const { tag, children } = props;

  if (tag === 'h1')
    return (
      <h1 className="mt-8">
        {children} <hr />
      </h1>
    );

  if (tag === 'h2')
    return (
      <h2 className="mt-8">
        {children} <hr />
      </h2>
    );

  if (tag === 'h3')
    return (
      <h3 className="mt-8">
        {children} <hr />
      </h3>
    );

  if (tag === 'h4') return <h4 className="mt-8 mb-2">{children}</h4>;

  if (tag === 'h5') return <h5 className="mt-8 mb-2">{children}</h5>;

  return null;
}
