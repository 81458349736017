import React, { useEffect, useState } from 'react';

import useStore from 'globalstate';
import Error from 'components/Error';
import PublicHackathonInformation from 'components/InformationPublic';
import Spinner from 'fragments/Spinner';
import { API_URL, HACKATHON_BASE_PATH } from 'constants/app';
import fetch from 'utils/fetch';
import { Redirect } from 'react-router';

interface IProps {
  hackathonId: string;
  path?: string;
  children: JSX.Element;
}

export default function Preloader(props: IProps) {
  const { hackathonId, path } = props;

  const [State, Actions] = useStore();
  const { toc, hasHackathonAccess } = State;

  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [error, seterror] = useState<string>();

  const calculateRemainingDays = (endDate: Date, startDate: Date) => {
    const end = endDate.getTime();
    const start = startDate.getTime();
    const today = new Date().getTime();
    const totalDays = Math.round((end - start) / (1000 * 60 * 60 * 24));
    const remainingDays = Math.ceil((end - today) / (1000 * 60 * 60 * 24));
    const daysElapsed = Math.ceil((today - start) / (1000 * 60 * 60 * 24)) - 1;
    Actions.setState({ daysElapsed, totalDays, remainingDays });
  };

  const getTOC = async (currentHackathon: string) => {
    try {
      const INDEX_API = `${API_URL}/contest/${currentHackathon}/page/index.json`;
      const resp = await fetch(INDEX_API, { credentials: 'header' });
      const data = JSON.parse(await resp.text());
      const parsedTOC = data.map((d: any) => (d ? { ...d, path: getHackathonPath(currentHackathon, d.path) } : d));
      Actions.setState({ toc: parsedTOC });
      sethasPreloaded(true);
    } catch (e) {
      seterror((e as Error).message);
      sethasPreloaded(true);
    }
  };

  const getHackathonPath = (currentHackathon: string, route: string) =>
    `${HACKATHON_BASE_PATH}/${currentHackathon}/${route}`;

  const getHackathonData = async (currentHackathon: string) => {
    try {
      const INDEX_API = `${API_URL}/contest/${currentHackathon}`;
      const resp = await fetch(INDEX_API, { credentials: 'header' });
      const respJSON = await resp.json();
      const contestData = respJSON.data;

      const { id, submissionCount, participantCount, isActive, practiceMode, start, end, externalId, title } =
        contestData;
      const contestStart = new Date(start);
      const contestEnd = new Date(end);
      const hasStarted = contestStart < new Date();
      Actions.setState({
        isContestActive: isActive,
        isPracticeActive: practiceMode,
        contestId: id,
        contestStart,
        contestEnd,
        hasStarted,
        submissionCount,
        participantCount,
      });
      calculateRemainingDays(contestEnd, contestStart);

      const hackathonData = { externalId, title };
      Actions.setState({ hackathonData });
      getTOC(currentHackathon);
    } catch (e) {
      seterror((e as Error).message);
      sethasPreloaded(true);
    }
  };

  useEffect(() => {
    sethasPreloaded(false);
    getHackathonData(hackathonId);
  }, [hackathonId]);

  if (!hasPreloaded)
    return (
      <div className="h-90vh w-full flex items-center justify-center">
        <div className="h-90vh w-full flex items-center justify-center">
          <Spinner bar />
        </div>
      </div>
    );

  if (!hasHackathonAccess) return <PublicHackathonInformation />;

  if (error) return <Error text={error} preset="broken" />;

  const firstTOC = toc[0].path;
  if (!path) return <Redirect to={firstTOC} />;

  return <div>{props.children}</div>;
}
