import React from 'react';
import { FaLock } from 'react-icons/fa';

import Error from 'components/Error';
import useStore from 'globalstate';

import TabbedBoard from './TabbedBoard';

export default function LeaderboardPublic() {
  const [State] = useStore();
  const { isLeaderboardOpen = true } = State;

  return (
    <div className="content">
      {isLeaderboardOpen ? (
        <TabbedBoard />
      ) : (
        <Error emotext="Closed" text="Leaderboard is currently closed." emoji={<FaLock />} />
      )}
    </div>
  );
}
