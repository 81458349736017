import React from 'react';
import { FaBook, FaLevelDownAlt, FaLevelUpAlt, FaLightbulb, FaAngleDoubleRight } from 'react-icons/fa';

import Card from 'fragments/Card';
import { ACTIVE_HACKATHON_PATH, PAST_HACKATHON_PATH } from 'constants/app';

export default function () {
  return (
    <div className="max-w-7xl mx-auto p-4 flex flex-col sm:flex-row items-center justify-center mx-20 sm:mt-20 sm:h-80vh">
      <Card
        style="sm:h-40vh"
        animate
        link={ACTIVE_HACKATHON_PATH}
        topContent={
          <div className="flex flex-row justify-between p-4">
            <div className="mb-4 text-xl w-3/4">
              <div>Compete</div>
              <div>Collaborate</div>
              <div>Become part of the community</div>
              <div>Win prizes</div>
              <div>Get access to jobs</div>
            </div>
            <div>
              <FaLightbulb className="text-2xl sm:text-4xl inline" />
              <FaLevelUpAlt className="inline" />
            </div>
          </div>
        }
        bottomContent={
          <React.Fragment>
            <div />
            <h3 className="text-white">
              Current Hackathons <FaAngleDoubleRight className="inline" />
            </h3>
          </React.Fragment>
        }
      />
      <div className="m-4 sm:mx-4" />
      <Card
        animate
        style="sm:h-40vh"
        link={PAST_HACKATHON_PATH}
        topContent={
          <div className="flex flex-row justify-between p-4">
            <div className="mb-4 text-xl w-3/4">
              <div>Learn</div>
              <div>View discussions</div>
              <div>Check out the community</div>
              <div>Practice</div>
              <div>See leaderboards</div>
            </div>
            <div>
              <FaBook className="text-2xl sm:text-4xl inline" />
              <FaLevelDownAlt className="inline" />
            </div>
          </div>
        }
        bottomContent={
          <React.Fragment>
            <div />
            <h3 className="text-white">
              Previous Hackathons <FaAngleDoubleRight className="inline" />
            </h3>
          </React.Fragment>
        }
      />
    </div>
  );
}
