import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Tabs from 'fragments/Tabs';

import HackathonList from './List';

const tabs = [{ label: 'active' }, { label: 'past' }, { label: 'practice' }];

export default function TabbedHackathons() {
  const history = useHistory();
  const { tab = 'active' } = useParams() as any;

  return (
    <div className="content mx-auto max-w-7xl">
      <h1>Hackathons</h1>
      <hr />
      <div className="flex flex-row items-center">
        <Tabs tabs={tabs} currentTab={tab} setTab={(tab: string) => history.replace(tab)} />
      </div>
      <div className={tab === 'active' ? 'block' : 'hidden'}>
        <HackathonList active />
      </div>
      <div className={tab === 'past' ? 'block' : 'hidden'}>
        <HackathonList />
      </div>
      <div className={tab === 'practice' ? 'block' : 'hidden'}>
        <HackathonList practice />
      </div>
    </div>
  );
}
