import React from 'react';

import ListChooser from 'components/List/ChooseList';

export default function Home() {
  return (
    <div className="overflow-x-hidden h-full">
      <ListChooser />
    </div>
  );
}
