import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import Table from 'components/Table';
import ErrorMsg from 'components/Error';
import Spinner from 'fragments/Spinner';
import fetch from 'utils/fetch';
import useStore from 'globalstate';
import { API_URL } from 'constants/app';

const getLeaderBoardAPI = (type: string) => `${API_URL}/contest/leaderboard/${type}/`;

type TScore = number | { [key: string]: number };
type TScoreAccessor = 'public_score' | 'private_score';

type TLeaderboard = {
  rank: number;
  name: string;
  detail: string | null;
  status: number;
  public_score?: TScore;
  private_score?: TScore;
}[];

type TColumn = {
  Header: string;
  accessor: string;
  width: string;
};

export default function Leaderboard({ type }: { type: 'public' | 'private' }) {
  const scoreAccessor: TScoreAccessor = `${type}_score`;
  const [leaderboard, setleaderboard] = useState<TLeaderboard>([]);
  const [loading, setloading] = useState<boolean>();
  const [showStudents, setshowStudents] = useState<boolean>(false);
  const [columns, setcolumns] = useState<TColumn[]>([]);
  const [error, seterror] = useState<string>();
  const [State] = useStore();

  const { contestId, isAuthenticated } = State;

  const processLeaderboard = (leaderboard: TLeaderboard) => {
    const scoreSample = leaderboard[0][scoreAccessor] as TScore;
    if (!scoreSample) return;

    const scoreKeys = Object.keys(scoreSample);
    if (typeof scoreSample !== 'number')
      leaderboard = leaderboard.map((l: any) => {
        const row = l;
        scoreKeys.forEach((key: string) => {
          row[key] = l[scoreAccessor][key];
        });
        return row;
      });
    setleaderboard(leaderboard);

    let scoreColumns = [];
    const scoreWidth = 20;
    if (typeof scoreSample === 'number')
      scoreColumns = [{ Header: `${type} Score`, accessor: scoreAccessor, width: `${scoreWidth}%` }];
    else
      for (const key in scoreKeys)
        scoreColumns.push({ Header: key, accessor: key, width: `${scoreWidth / scoreKeys.length}%` });

    setcolumns([
      { Header: 'Rank', accessor: 'rank', width: '5%' },
      { Header: 'Name', accessor: 'name', width: '20%' },
      { Header: 'Institution / Organization', accessor: 'detail', width: '30%' },
      ...scoreColumns,
    ]);
  };

  const getleaderboard = async () => {
    setloading(true);
    const contestantType = showStudents ? 'student' : 'all';
    const resp = await fetch(`${getLeaderBoardAPI(type)}${contestId}?contestant=${contestantType}`, {
      credentials: 'header',
    });
    try {
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const leaderboard = respJSON.data;
      processLeaderboard(leaderboard);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  useEffect(() => {
    if (contestId) getleaderboard();
  }, [contestId, showStudents]);

  // highlight user rows
  const highlight = isAuthenticated
    ? [{ key: 'userId', shouldHighlight: (userId: number) => State.userDetails.userId === userId }]
    : undefined;

  return (
    <div className="content">
      {(() => {
        if (loading) return <Spinner bar space />;
        if (error) return <ErrorMsg preset="broken" text={error} />;
        if (!leaderboard.length)
          return (
            <ErrorMsg
              preset="nothing"
              text="No submissions have been evaluated. Start hacking to open the leaderboard!"
            />
          );
        return (
          <React.Fragment>
            <div className="text-gray-500 text-sm">
              <div className="flex items-center mt-2 mb-4 text-gray-400 mr-2 text-xs uppercase">
                <span>Students & working professionals</span>
                <Switch
                  className="mx-2"
                  onColor="#878fe3"
                  height={20}
                  width={42}
                  onChange={() => setshowStudents(!showStudents)}
                  checked={showStudents}
                />
                <span>Students-only</span>
              </div>
            </div>
            <Table columns={columns} data={leaderboard} highlight={highlight} doPaginate doSearch />
          </React.Fragment>
        );
      })()}
    </div>
  );
}
