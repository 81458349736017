import React, { useEffect, useMemo, useState, useRef } from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle, FaStopCircle } from 'react-icons/fa';

import ErrorMsg from 'components/Error';
import Spinner from 'fragments/Spinner';
import fetch from 'utils/fetch';
import useStore from 'globalstate';
import { API_URL } from 'constants/app';

import MakeSubmission from './MakeSubmission';

export default function Submission() {
  const [userStatus, setuserStatus] = useState<number>(3);
  const [submissionCount, setsubmissionCount] = useState<number>();
  const [maxSubmissions, setmaxSubmissions] = useState<number>();
  const [timeRemaining, setTimeRemaining] = useState<number>();
  const [score, setscore] = useState<any>();
  const [loading, setloading] = useState<boolean>(true);
  const [error, seterror] = useState<string>();

  const timer = useRef<NodeJS.Timeout>();
  const [State] = useStore();
  const { contestId, isContestActive, isPracticeActive } = State;

  const canSubmit = (isContestActive || isPracticeActive) && userStatus && userStatus !== 3;

  const STATUS_API = `${API_URL}/contestant/get-status/${contestId}`;

  const getStatus = async () => {
    try {
      const resp = await fetch(STATUS_API, { credentials: 'header' });
      const respJSON = await resp.json();
      if (respJSON.error) throw new Error(respJSON.error);
      const userStatus = respJSON.data.status.statusCode;
      const { maxSubmissionsPerRateLimit, submissionCountPerRateLimit, nextSubmissionSlot } = respJSON.data;
      if (userStatus === 3 && nextSubmissionSlot) initiateTimer(new Date(nextSubmissionSlot).getTime() - Date.now());
      setuserStatus(userStatus);
      setsubmissionCount(submissionCountPerRateLimit);
      setmaxSubmissions(maxSubmissionsPerRateLimit);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  const onCompleteSubmission = (score: string) => setscore(score);

  const runTimer = () => {
    setTimeRemaining(currentTimeRemaining => {
      if (!currentTimeRemaining) return 0;
      const newTime = currentTimeRemaining - 1000;
      const val = newTime < 0 ? 0 : newTime;
      if (val === 0) {
        if (timer.current) clearInterval(timer.current);
        getStatus();
      }
      return val;
    });
  };

  const initiateTimer = (timeRemaining: number) => {
    setTimeRemaining(timeRemaining);
    timer.current = setInterval(runTimer, 1000);
  };

  useEffect(() => {
    getStatus();
  }, [score]);

  const scoreBanner = score ? (
    <div className="bg-green-50 text-green-500 p-4 flex items-center justify-center mb-4">
      <FaCheckCircle className="mr-2 text-xl" />
      <span>
        A submission has been made! You scored -{' '}
        {typeof score === 'number' ? (
          <div>{score}</div>
        ) : (
          (Object.keys(score) as Array<keyof typeof score>).map(key => (
            <div>
              <span className="uppercase text-xs ml-2">{key}</span>: {`${score[key]}`}
            </div>
          ))
        )}
      </span>
    </div>
  ) : null;

  const allowedBanner = (
    <div className="bg-green-50 text-green-500 p-4 flex items-center justify-center">
      <FaCheckCircle className="mr-2 text-xl" />
      <span>You are all set to make your submission</span>
    </div>
  );

  const ratelimitedBanner = (
    <div className="bg-red-50 text-red-500 p-4 flex items-center justify-center">
      <FaExclamationTriangle className="mr-2 text-3xl" />
      <span>Submission limits reset 24 hours after a submission. Please try again after the time has elapsed.</span>
    </div>
  );

  const limitBanner = (
    <div className="bg-red-50 text-red-500 p-4 flex items-center justify-center">
      <FaExclamationTriangle className="mr-2 text-xl" />
      <span>
        Participants are allowed only {maxSubmissions} submissions this hackathon. You cannot make any more submissions.
      </span>
    </div>
  );

  // aka banneder
  const bannedBanner = (
    <div className="bg-red-50 text-red-500 p-4 flex items-center justify-center">
      <FaExclamationTriangle className="mr-2 text-xl" />
      <span>You have been barred from making submissions.</span>
    </div>
  );

  const practiceBanner = (
    <div className="bg-blue-50 text-blue-500 p-4 flex items-center justify-center">
      <FaInfoCircle className="mr-2 text-xl" />
      <span>This hackathon is in practice mode. There are no awards.</span>
    </div>
  );

  const currentStatusBanner = useMemo(() => {
    if (!userStatus && userStatus !== 0) return null;
    if (!isPracticeActive) return practiceBanner;
    if (userStatus === 3) return ratelimitedBanner;
    if (userStatus === 4) return bannedBanner;
    if (userStatus === 5) return limitBanner;
    if (isContestActive && userStatus === 2) return allowedBanner;
    return null;
  }, [userStatus, maxSubmissions]);

  if (!State.isAuthenticated) return <ErrorMsg embed={false} preset="login" />;
  if (!(isContestActive || isPracticeActive))
    return (
      <ErrorMsg
        embed={false}
        emotext="Submissions are closed"
        text="The window for submissions for this hackathon, is now closed. Results will be available from the leaderboard. Note that this hackathon currently is not open for practice but maybe opened in the future."
        emoji={<FaStopCircle />}
      />
    );
  if (loading) return <Spinner bar space />;
  if (error) return <ErrorMsg preset="broken" text={error} />;
  if (!userStatus && userStatus !== 0)
    return <ErrorMsg preset="broken" text="We are having some problems fetching your data. Please try again later" />;
  return (
    <div className="content">
      <h1>Make your submission</h1>
      <hr />
      {scoreBanner}
      {currentStatusBanner}
      <div className="tracking-wide text-sm font-space my-4 flex text-brand uppercase justify-between">
        <div />
        {timeRemaining
          ? (() => {
              const oneMin = 1000 * 60;
              const oneHour = oneMin * 60;
              const hoursRemaining = Math.floor(timeRemaining / oneHour);
              let remainingTime = timeRemaining % oneHour;
              const minutesRemaining = Math.floor(remainingTime / oneMin);
              remainingTime = remainingTime % oneMin;
              const secondsRemaining = Math.ceil(remainingTime / 1000) - 1;
              const timeString = `${hoursRemaining ? `${String(hoursRemaining).padStart(2, '0')} : ` : ''}
          ${minutesRemaining ? `${String(minutesRemaining).padStart(2, '0')} : ` : ''}
          ${secondsRemaining === 0 || secondsRemaining ? String(secondsRemaining).padStart(2, '0') : ''}`;

              return (
                <div className="text-gray-500 text-xs">
                  Time to next submission<span className="text-brand text-sm"> {timeString}</span>
                </div>
              );
            })()
          : null}
        {canSubmit && maxSubmissions ? (
          <div>
            {(() => {
              if (!submissionCount) return `Max submissions per day: ${maxSubmissions}`;
              return `Submissions today: ${submissionCount} / ${maxSubmissions}`;
            })()}
            <div className="text-gray-500 text-xs">limits are reset 24 hours after a submission</div>
          </div>
        ) : null}
      </div>
      {canSubmit ? <MakeSubmission onComplete={onCompleteSubmission} /> : null}
    </div>
  );
}
