import React, { useEffect, useState } from 'react';

import ErrorMsg from 'components/Error';
import Spinner from 'fragments/Spinner';
import fetch from 'utils/fetch';
import { HACKATHON_API } from 'constants/app';
import { THackathon } from 'types';

import HackathonCard from './Card';

export default function HackathonList({ active = false, practice = false }: { active?: boolean; practice?: boolean }) {
  const [hackathons, sethackathons] = useState<THackathon[]>();
  const [loading, setloading] = useState<boolean>(true);
  const [error, seterror] = useState<string>('');
  const showPracticeIndicator = !active && !practice;

  const getHackathons = async () => {
    try {
      const resp = await fetch(
        `${HACKATHON_API}${active ? '?active=1' : '?active=0'}${practice ? '&practice=1' : ''}`,
        {
          credentials: 'header',
        }
      );
      const respJSON = await resp.json();
      if (resp.status !== 200) throw Error(respJSON.error);

      const hackathonData = respJSON.data;
      sethackathons(hackathonData);
      setloading(false);
    } catch (e) {
      seterror((e as Error).message);
      setloading(false);
    }
  };

  useEffect(() => {
    getHackathons();
  }, []);

  return (
    <div className="mt-8 sm:mx-8 xl:mx-0">
      <div className="flex flex-col sm:flex-row">
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorMsg text={error} preset="broken" />
        ) : !hackathons || !hackathons.length ? (
          <ErrorMsg embed={false} preset="nothing" text="No hackathons here at the moment" />
        ) : (
          hackathons &&
          hackathons.map((h: THackathon) => (
            <HackathonCard {...h} key={h.id} showPracticeIndicator={showPracticeIndicator} />
          ))
        )}
      </div>
    </div>
  );
}
