import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV === 'production')
  Sentry.init({
    dsn: 'https://41e2235661af4811b0da666044d93206@o387419.ingest.sentry.io/6025851',
    // integrations: [new Integrations.BrowserTracing()],
    // tracesSampleRate: 1.0,
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
