/* eslint-disable */
// @ts-nocheck
import React, { useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useAsyncDebounce, useGlobalFilter, usePagination, useTable, useSortBy } from 'react-table';
import { matchSorter } from 'match-sorter';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaTimes } from 'react-icons/fa';

interface IProps {
  doPaginate?: boolean;
  doSearch?: boolean;
  doSort?: boolean;
  columns: { Header: string; accessor: string }[];
  data: any[];
  highlight?: { key: string; shouldHighlight: (val: any) => boolean; color?: string }[];
}

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = val => !val;

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="text-sm w-32"
      placeholder={`Search`}
      value={filterValue || ''}
    />
  );
}

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChange = (q: string) => {
    setValue(q);
    onChange(q);
  };

  return (
    <div className="flex flex-row items-center uppercase text-gray-500 text-sm ring-gray-300 bg-gray-100">
      <input
        value={value}
        className="ml-2 text-sm w-64 focus:ring-0"
        onChange={e => {
          handleChange(e.target.value);
        }}
        placeholder="Search"
      />
      <div
        onClick={() => {
          handleChange('');
        }}
        className="p-2 ml-2 cursor-pointer"
      >
        <FaTimes />
      </div>
    </div>
  );
}

export default function (props: IProps) {
  const { columns, data, highlight, doPaginate = false, doSearch = false, doSort = true } = props;

  // const filterTypes: any = React.useMemo(() => ({ fuzzyText: fuzzyTextFilterFn }), []);

  const defaultColumn: any = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInvokers = [doSearch && useGlobalFilter, doSort && useSortBy, doPaginate && usePagination].filter(
    inv => !!inv
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    pageCount,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    ...tableInvokers
  );

  const getRowProps =
    highlight && highlight.length
      ? row => {
          const maybeHighlight = highlight.find(({ key, shouldHighlight }) => shouldHighlight(row.original[key]));
          return {
            className: maybeHighlight ? maybeHighlight.color || 'bg-brand-lightest' : '',
          };
        }
      : () => {};

  // separate out for mobile sorting
  const headers: any[] = [];

  const search = doSearch ? (
    data && data.length > 10 ? (
      <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
    ) : null
  ) : null;

  const pagination = doPaginate ? (
    pageCount && pageCount !== 1 ? (
      <div className="text-sm flex flex-row items-center space-right">
        <button
          className={!canPreviousPage ? 'secondary disabled' : ''}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FaAngleDoubleLeft />
        </button>
        <button
          className={!canPreviousPage ? 'secondary disabled' : ''}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft />
        </button>
        <div>{`${state.pageIndex + 1} / ${pageCount}`}</div>
        <button className={!canNextPage ? 'secondary disabled' : ''} onClick={() => nextPage()} disabled={!canNextPage}>
          <FaAngleRight />
        </button>
        <button
          className={!canNextPage ? 'secondary disabled' : ''}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    ) : null
  ) : null;

  headerGroups.forEach(headerGroup =>
    headerGroup.headers.forEach((column: any) => {
      headers.push({
        props: column.getHeaderProps(column.getSortByToggleProps()),
        col: column.render('Header'),
        icon: (
          <span className="inline-flex text-xs ml-2">
            {column.isSorted ? column.isSortedDesc ? <FaChevronUp /> : <FaChevronDown /> : ''}
          </span>
        ),
      });
    })
  );

  useEffect(() => {
    if (!doPaginate || !setPageSize) return;
    if (window.innerWidth < 768) setPageSize(3);
  }, [doPaginate, setPageSize]);

  if (!data.length) return <div className="w-full text-center p-4">No data found</div>;

  const dataElement = doPaginate ? page : rows;

  return (
    <>
      <div className="mt-2">
        {(!!search || !!pagination) && (
          <div className="flex flex-col w-full justify-between items-center my-4 md:flex-row space-right">
            <div className="flex justify-center md:block md:w-auto md:justify-start">{search}</div>
            <div className="mt-4 md:mt-0">{pagination}</div>
          </div>
        )}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={`th-${column.id}`}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: { maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <span className="inline-flex text-xs ml-2">
                        {column.isSortedDesc ? <FaChevronUp /> : <FaChevronDown />}
                      </span>
                    )}
                    {/* <div className="mt-4">{column.canFilter ? column.render('Filter') : null}</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {dataElement.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => (
                    <td
                      title={cell.value}
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.minWidth,
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="block thead mt-8 md:hidden">
        {dataElement.map(row => {
          return (
            <div key={`mrow-${row.id}`}>
              {row.cells.map((cell, i) => {
                const cellHeader = headers[i];
                return (
                  <div key={`mrow-${row.id}-mcell-${cell.column.id}`} className="flex justify-between md:py-2">
                    <p
                      className="text-gray-500 w-1/6 font-space uppercase text-xs whitespace-nowrap"
                      {...cellHeader.props}
                    >
                      {cellHeader.col}
                      {cellHeader.icon}
                    </p>
                    <p className="w-4/6 cursor-pointer">{cell.render('Cell')}</p>
                  </div>
                );
              })}
              <hr className="my-4" />
            </div>
          );
        })}
      </div>
    </>
  );
}
