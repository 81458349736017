import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactModal from 'react-modal';

import ScrollToTop from 'fragments/ScrollToTop';
import Home from 'components/Home';
import HackathonList from 'components/List';
import Preloader from 'components/Preloader';
import MarkdownPage from 'components/Page';
import HackathonPreloader from 'components/Preloader/Hackathon';
import TopBar from 'components/Navbar';
import HackathonNavBar from 'components/Navbar/Hackathon';
import Register from 'components/Register';
import Error from 'components/Error';
import { HACKATHON_BASE_PATH, REGISTER_PATH, CURRENT_HACKATHON_PATH, HACKATHON_LIST_PATH } from 'constants/app';
import 'styles/App.css';
import 'styles/App.scss.css';

ReactModal.setAppElement('body');

// hackathon routes
function HackathonRoutes({ hackathonId, path }: { hackathonId: string; path?: string }) {
  return (
    <HackathonPreloader hackathonId={hackathonId} path={path}>
      <div className="flex flex-column max-w-screen-xl mx-auto">
        <HackathonNavBar hackathonId={hackathonId} />
        <div className="w-full">
          <Switch>
            <Route
              exact
              path={`${HACKATHON_BASE_PATH}/:hackathonId/:path`}
              render={props => (
                <MarkdownPage hackathonId={props.match.params.hackathonId} page={props.match.params.path} />
              )}
            />
            <Route>
              <Error preset="nothing" embed={false} />
            </Route>
          </Switch>
        </div>
      </div>
    </HackathonPreloader>
  );
}

// xl screens
function NarrowRoutes() {
  return (
    <div>
      <TopBar />
      <div className="flex flex-column max-w-screen-xl mx-auto">
        <div className="w-full">
          <Switch>
            <Route
              path={`${HACKATHON_BASE_PATH}/:hackathonId/:path?`}
              render={(props: any) => {
                const { hackathonId, path } = props.match.params;
                return <HackathonRoutes hackathonId={hackathonId} path={path} />;
              }}
            />
            <Route exact path={`${HACKATHON_LIST_PATH}/:tab?`}>
              <HackathonList />
            </Route>
            <Route path={HACKATHON_BASE_PATH}>
              <Redirect to={CURRENT_HACKATHON_PATH} />
            </Route>
            <Route>
              <Error preset="nothing" embed={false} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

// fullwidth screens
function App() {
  return (
    <Router>
      <Preloader>
        <div>
          <ScrollToTop />
          <Switch>
            <Route path={`${REGISTER_PATH}/:hackathonId`}>
              <Register />
            </Route>
            <Route exact path="/">
              <TopBar />
              <Home />
            </Route>
            <Route>
              <NarrowRoutes />
            </Route>
          </Switch>
        </div>
      </Preloader>
    </Router>
  );
}

export default App;
