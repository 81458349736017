import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

import useStore from 'globalstate';
import { getLoginURL, getProfileURL } from 'constants/app';

export default function Home() {
  const [State] = useStore();
  const { isAuthenticated } = State;
  const loginURL = getLoginURL(window.location.href);
  const profileURL = getProfileURL(window.location.href, 'hackathon');

  return (
    <div className="w-full my-20">
      <div className="p-8 md:p-4 flex flex-col sm:flex-row items-center">
        <div className="w-full sm:w-1/2">
          <div className="w-full sm:w-1/2">
            <div className="text-3xl tracking-normal">
              {isAuthenticated ? 'Complete your registration' : 'Login to your account'} to start hacking and win
              prizes!
            </div>
            <div className="mt-4">
              {!isAuthenticated ? (
                <a href={loginURL} className="button dark text-xl">
                  Login <FaLongArrowAltRight className="ml-4 inline" />
                </a>
              ) : (
                <div className="text-white whitespace-nowrap text-xl">
                  {(() => {
                    return (
                      <a href={profileURL} className="button dark text-xl">
                        Register <FaLongArrowAltRight className="ml-4 inline" />
                      </a>
                    );
                  })()}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 mt-8 sm:mt-0">
          <img src="/register.png" />
          <div className="text-sm text-gray-500 text-center italic">
            Hackathon Platform - Register to get full access
          </div>
        </div>
      </div>
    </div>
  );
}
