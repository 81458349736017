import React from 'react';
import { FaCircle, FaUser, FaRegCalendar, FaRegCalendarAlt, FaLongArrowAltRight } from 'react-icons/fa';

import { HACKATHON_BASE_PATH } from 'constants/app';
import { THackathon } from 'types';
import Card from 'fragments/Card';

export default function HackathonCard(props: THackathon) {
  const {
    id,
    title,
    difficulty,
    description,
    externalId,
    participantCount,
    start,
    end,
    showPracticeIndicator,
    practiceMode,
  } = props;
  const startDate = new Date(start).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  const endDate = new Date(end).toLocaleDateString(undefined, { month: 'short', day: 'numeric' });

  return (
    <div className="w-full sm:w-96 mb-4 sm:mr-4">
      <Card
        link={`${HACKATHON_BASE_PATH}/${id}`}
        topContent={
          <div>
            <div className="mx-2 mt-2 text-gray-500">
              <div className="flex flex-row justify-between items-center">
                <div>
                  {participantCount && (
                    <span>
                      <FaUser className="inline mr-2" /> {participantCount}
                    </span>
                  )}
                  <div className="sm:inline">
                    {participantCount && showPracticeIndicator && practiceMode && (
                      <span className="hidden sm:inline">{' · '}</span>
                    )}
                    {showPracticeIndicator && practiceMode && (
                      <div className="bg-brand text-white text-xs p-1 mt-2 sm:inline sm:mt-0 w-min">Practice</div>
                    )}
                  </div>
                </div>
                <div>
                  <span className="text-brand font-semibold">
                    {startDate} <FaRegCalendar className="inline" />
                  </span>
                  <FaLongArrowAltRight className="inline mx-2" />
                  <span className="text-red-500 font-semibold">
                    {endDate} <FaRegCalendarAlt className="inline" />
                  </span>
                </div>
              </div>
            </div>
            {description && (
              <div className="p-4 text-lg">
                {description.substr(0, 266)} {description.length > 266 ? '...' : ''}
              </div>
            )}
          </div>
        }
        bottomContent={
          <div className="w-full">
            <div className="flex flex-row justify-between items-center text-white">
              <div className="flex-1">
                <FaCircle
                  className={`inline ${
                    difficulty === 'Beginner'
                      ? 'text-green-400'
                      : difficulty === 'Intermediate'
                      ? 'text-blue-500'
                      : 'text-red-600'
                  }`}
                />{' '}
                {difficulty}
              </div>
              <div>Hackathon {externalId}</div>
            </div>
            <h3 className="mt-2 float-right text-lg text-white">{title}</h3>
          </div>
        }
      />
    </div>
  );
}
