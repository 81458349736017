interface IOptions {
  method?: string;
  headers?: { [key: string]: string } | null;
  body?: string | FormData | File;
  credentials?: string;
}

export interface XHRResponse {
  status: number;
  text: string;
  raw: string;
}

export default function (url: string, opts: IOptions = {}, onProgress?: (e: ProgressEvent) => void): Promise<any> {
  const { credentials, headers, ...options } = opts;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if (credentials === 'include') xhr.withCredentials = true;

    xhr.open(options.method || 'get', url);

    if (headers) for (const header in headers) xhr.setRequestHeader(header, headers[header]);

    xhr.onload = () => {
      const text = xhr.responseText;
      const status = xhr.status;
      const statusText = xhr.statusText;
      const rawResponse = xhr.response;
      const respBody = { status, statusText, text, raw: rawResponse };
      resolve(respBody);
    };

    xhr.onerror = reject;

    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;

    xhr.send(options.body);
  });
}
